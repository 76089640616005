import { getData } from '../redux/fetchService/baseFetch';
import { PartnerSettingsAPIPayload } from '../typedefs';
import { createApiThunk } from './utils/ApiThunkCreator';

export default class PartnerAPI {
  fetchPartnerSettings = async ({
    partnerSlug,
  }: {
    partnerSlug: string;
    prefetchedData?: PartnerSettingsAPIPayload;
  }): Promise<PartnerSettingsAPIPayload | undefined> => {
    return getData(`partners/${partnerSlug}`);
  };

  fetchAllPartnerSlugs = async (): Promise<{ partners: string[] } | undefined> => {
    return getData(`partners`);
  };
}

export const fetchPartnerApiThunk = createApiThunk('partnerApi/get', new PartnerAPI().fetchPartnerSettings);
