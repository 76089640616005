import { createSelector, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '.';
import { fetchNeighborhoodInfoApiThunk } from '../../api/NeighborhoodAPI';
import type { NeighborhoodState, UtilityProvider } from '../../typedefs';
import { EMPTY_LIST } from '../../utils/constants';
import { UtilityProviderServiceType, type RebateIncomeQualificationCriteria } from '../../utils/enums';
import FilterUtil from '../../utils/filterUtil';

const initialState: NeighborhoodState = {
  utilityProviders: [],
};

const slice = createSlice({
  name: 'neighborhood',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchNeighborhoodInfoApiThunk.fulfilled, (_, action) => {
      if (action.payload) {
        return {
          ...action.payload,
          isNeighborhoodDataLoaded: true,
        };
      }
    });
  },
});

export const isNeighborhoodDataLoaded = (store: RootState) => store.neighborhood.isNeighborhoodDataLoaded;

const isElectricProvider = (provider: UtilityProvider) => provider.serviceType === UtilityProviderServiceType.ELECTRIC;

export const getUtilityProviders = (store: RootState) => store.neighborhood.utilityProviders || EMPTY_LIST;

export const hasMoreThanOneUtilityProvider = (store: RootState) => {
  const providers = getUtilityProviders(store);
  return providers.length > 1;
};

export const getUtilityProvidersByType = createSelector(
  [getUtilityProviders],
  (utilityProviders: UtilityProvider[]) => {
    const { matches: electric, nonmatches: gas } = FilterUtil.splitListByFilterMatch(
      utilityProviders,
      isElectricProvider,
    );
    return {
      electric: electric || EMPTY_LIST,
      gas: gas || EMPTY_LIST,
    };
  },
);

export const hasMoreThanOneElectricUtilityProvider = (store: RootState) => {
  const { electric } = getUtilityProvidersByType(store);
  return electric.length > 1;
};

export const hasMoreThanOneGasUtilityProvider = (store: RootState) => {
  const { gas } = getUtilityProvidersByType(store);
  return gas.length > 1;
};

export const getHudAmi = (store: RootState) => store.neighborhood.hudAmi;

export const getNeighborhoodEligibleForIncomeBracketedRebates = (store: RootState): boolean =>
  store.neighborhood.rebateEligibility?.eligibleForIncomeBracketedRebates || false;

export const getNeighborhoodPossibleIncomeQualificationCriteria = (
  store: RootState,
): RebateIncomeQualificationCriteria[] => {
  return store.neighborhood.rebateEligibility?.possibleIncomeQualificationCriteria || EMPTY_LIST;
};

export default slice.reducer;
