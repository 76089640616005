import { isNil, omitBy } from 'lodash-es';

const ObjectUtil = {
  omitEmptyValues<T extends object>(data: T): Partial<T> {
    return omitBy<T>(data, isNil);
  },
  isEmpty(obj: object | undefined): boolean {
    return !obj || Object.keys(obj).length === 0;
  },
};

export default ObjectUtil;
