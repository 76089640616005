import { filter, findKey, replace, trim } from 'lodash-es';
import type {
  EquipmentRecommendationAPIPayload,
  EquipmentType,
  FurnaceAPIPayload,
  InternalModelOptionsAPIPayload,
  ModelAPIPayload,
  ProductTierLabelOverrideValues,
  ProductTierLabelOverrides,
  SystemConfigurationAPIPayload,
} from '../typedefs';
import { PRODUCT_TIER_LABEL_OVERRIDE_KEYS } from './constants';
import {
  HeatPumpDesignType,
  HeatPumpTypeInfo,
  PRODUCT_TIER_LABELS,
  ProductType,
  SystemType,
  type ProductTier,
} from './enums';

const MODEL_NUMBER_BRAND_HACK_REGEX = /\(.*\)$/;

function getCustomProductTierLabel(
  productTier: ProductTier,
  productTierLabelOverrides: ProductTierLabelOverrides | undefined,
) {
  if (!productTierLabelOverrides) return undefined;

  const keyForTierLabel = findKey(PRODUCT_TIER_LABEL_OVERRIDE_KEYS, (value) => value === productTier) as
    | ProductTierLabelOverrideValues
    | undefined;
  if (!keyForTierLabel) return undefined;

  const tierLabel = productTierLabelOverrides[keyForTierLabel];
  return tierLabel || undefined;
}

function isSystemConfiguration(equipment?: EquipmentType): equipment is SystemConfigurationAPIPayload {
  if (!equipment) return false;
  return 'id' in equipment;
}

function isModel(equipment?: EquipmentType): equipment is ModelAPIPayload {
  if (!equipment) return false;
  return 'modelId' in equipment;
}

function isProject(
  projectOrSystem: EquipmentRecommendationAPIPayload | SystemConfigurationAPIPayload,
): projectOrSystem is EquipmentRecommendationAPIPayload {
  return (projectOrSystem as EquipmentRecommendationAPIPayload).productTier !== undefined;
}

// This doubles as a type guard (not undefined) and a logic check.
function isAcSystem(
  systemConfiguration?: SystemConfigurationAPIPayload,
): systemConfiguration is SystemConfigurationAPIPayload {
  if (!systemConfiguration) return false;
  return systemConfiguration.productType === ProductType.AIR_CONDITIONER;
}

// This doubles as a type guard (not undefined) and a logic check.
function isUnitarySystem(
  systemConfiguration?: SystemConfigurationAPIPayload,
): systemConfiguration is SystemConfigurationAPIPayload {
  if (!systemConfiguration) return false;
  return systemConfiguration.systemType === SystemType.UNITARY_DUCTED;
}

// This doubles as a type guard (not undefined) and a logic check.
function isPackagedSystem(
  systemConfiguration?: SystemConfigurationAPIPayload,
): systemConfiguration is SystemConfigurationAPIPayload {
  if (!systemConfiguration) return false;
  return systemConfiguration.systemType === SystemType.PACKAGED_DUCTED;
}

// This doubles as a type guard (not undefined) and a logic check.
function isPackagedSystemWithFurnace(
  systemConfiguration?: SystemConfigurationAPIPayload,
): systemConfiguration is SystemConfigurationAPIPayload {
  if (!isPackagedSystem(systemConfiguration)) return false;
  return !!systemConfiguration?.externalModel.packagedAfue;
}

// This doubles as a type guard (not undefined) and a logic check.
function isSystemWithFurnace(
  systemConfiguration?: SystemConfigurationAPIPayload,
): systemConfiguration is SystemConfigurationAPIPayload {
  if (!systemConfiguration) return false;
  return !!systemConfiguration.furnace || !!systemConfiguration?.externalModel.packagedAfue;
}

function getSystemConfigurationLabel(
  equipment: SystemConfigurationAPIPayload | undefined | null,
  internalModelOptions?: InternalModelOptionsAPIPayload,
) {
  if (!equipment) return null;

  const { externalModel, internalModel, furnace, quarterTons } = equipment;
  const externalModelBrandName = externalModel?.brandName;
  const tonnage = `${quarterTons / 4.0}tn`;
  const internalModelOptionsLabel = internalModelOptions && `IMO: ${internalModelOptions.slug}`;

  const tokens = filter([
    externalModelBrandName,
    tonnage,
    externalModel.modelNumber,
    internalModel?.modelNumber,
    internalModelOptionsLabel,
    furnace?.modelNumber,
  ]);
  return tokens.join(' | ');
}

function getModelOrFurnaceLabel(
  equipment: ModelAPIPayload | FurnaceAPIPayload | undefined | null,
  includeBrand: boolean = true,
) {
  if (!equipment) return null;
  const { brandName } = equipment;
  const displayBrandName = includeBrand ? `${brandName} ` : '';
  return `${displayBrandName}${equipment.modelNumber}`;
}

function getHeatPumpDesignTypeForAC(systemConfiguration: SystemConfigurationAPIPayload) {
  const { coil, furnace } = systemConfiguration;

  if (isPackagedSystemWithFurnace(systemConfiguration)) {
    return HeatPumpDesignType.PACKAGED_AC_AND_FURNACE;
  }

  if (coil) {
    if (furnace) {
      return HeatPumpDesignType.AC_AND_FURNACE;
    }
    return HeatPumpDesignType.AC;
  }
  return HeatPumpDesignType.AC_AND_AH;
}

function getHeatPumpDesignTypeForHP(systemConfiguration: SystemConfigurationAPIPayload) {
  const { systemType, coil, furnace, coldClimate } = systemConfiguration;

  if (isPackagedSystem(systemConfiguration)) {
    if (isSystemWithFurnace(systemConfiguration)) {
      return HeatPumpDesignType.PACKAGED_HYBRID_WITH_FURNACE;
    }
    return HeatPumpDesignType.PACKAGED_HEAT_PUMP;
  }

  if (coil) {
    if (furnace) {
      return HeatPumpDesignType.HYBRID_HEAT_PUMP_AND_FURNACE;
    }
    return HeatPumpDesignType.HYBRID_HEAT_PUMP;
  }
  if (coldClimate) {
    if (systemType === SystemType.SINGLE_ROOM) {
      return HeatPumpDesignType.COLD_CLIMATE_SINGLE_ZONE;
    }
    if (systemType === SystemType.MULTIPLE_ROOMS) {
      return HeatPumpDesignType.COLD_CLIMATE_MULTI_ZONE;
    }
    return HeatPumpDesignType.COLD_CLIMATE_HEAT_PUMP;
  }
  if (systemType === SystemType.SINGLE_ROOM) {
    return HeatPumpDesignType.REGULAR_SINGLE_ZONE;
  }
  if (systemType === SystemType.MULTIPLE_ROOMS) {
    return HeatPumpDesignType.REGULAR_MULTI_ZONE;
  }
  return HeatPumpDesignType.REGULAR_HEAT_PUMP;
}

// Maybe rename to EquipmentUtil
const ModelUtil = {
  getSanitizedModelNumber(modelNumber: string): string {
    return replace(trim(modelNumber), MODEL_NUMBER_BRAND_HACK_REGEX, '');
  },
  getIndoorUnitTitle(systemType: SystemType, designType: HeatPumpDesignType) {
    if (systemType === SystemType.UNITARY_DUCTED) {
      if (
        designType === HeatPumpDesignType.HYBRID_HEAT_PUMP ||
        designType === HeatPumpDesignType.HYBRID_HEAT_PUMP_AND_FURNACE
      ) {
        return 'Indoor Coil';
      }
      return 'Air Handler (Indoor)';
    }
    return 'Indoor Unit';
  },
  getExternalUnitTitle(systemConfiguration: SystemConfigurationAPIPayload) {
    if (isAcSystem(systemConfiguration)) {
      return 'AC (Outdoor)';
    }
    if (isPackagedSystem(systemConfiguration)) {
      return 'Outdoor Unit';
    }
    return 'Heat Pump (Outdoor)';
  },
  getProductTypeLabel(productType: ProductType) {
    switch (productType) {
      case ProductType.HEAT_PUMP:
        return 'Heat Pump';
      case ProductType.AIR_CONDITIONER:
        return 'Air Conditioner';
      default:
        return 'Unknown';
    }
  },
  getShortProductTypeLabel(productType: ProductType) {
    switch (productType) {
      case ProductType.HEAT_PUMP:
        return 'HP';
      case ProductType.AIR_CONDITIONER:
        return 'AC';
      default:
        return 'n/a';
    }
  },
  getSystemTypeLabel(systemType: SystemType) {
    switch (systemType) {
      case SystemType.MULTIPLE_ROOMS:
        return 'Minisplit multi-zone';
      case SystemType.SINGLE_ROOM:
        return 'Minisplit single-zone';
      case SystemType.UNITARY_DUCTED:
        return 'Ducted';
      case SystemType.PACKAGED_DUCTED:
        return 'Packaged';
      default:
        return 'Unknown';
    }
  },
  isSystemConfiguration,
  isModel,
  isAcSystem,
  isUnitarySystem,
  isPackagedSystem,
  isPackagedSystemWithFurnace,
  isSystemWithFurnace,
  getEquipmentId(equipment: EquipmentType | undefined) {
    if (!equipment) return null;

    if (isSystemConfiguration(equipment)) {
      return equipment.id;
    }

    if (isModel(equipment)) {
      return equipment.modelId;
    }

    return equipment.furnaceId;
  },
  getEquipmentLabel(equipment: EquipmentType | undefined, internalModelOptions?: InternalModelOptionsAPIPayload) {
    if (!equipment) return null;

    if (isSystemConfiguration(equipment)) {
      return getSystemConfigurationLabel(equipment, internalModelOptions);
    }

    return getModelOrFurnaceLabel(equipment);
  },
  getSystemConfigurationLabel,
  getModelOrFurnaceLabel,
  getHeatPumpDesignType(projectOrSystem: EquipmentRecommendationAPIPayload | SystemConfigurationAPIPayload) {
    if (isProject(projectOrSystem) && projectOrSystem.furnace) {
      return HeatPumpDesignType.FURNACE_ONLY;
    }

    const systemConfiguration = isProject(projectOrSystem) ? projectOrSystem.systemConfiguration : projectOrSystem;
    if (!systemConfiguration) return undefined;

    const isAcSystemFlag = isAcSystem(systemConfiguration);
    if (isAcSystemFlag) {
      return getHeatPumpDesignTypeForAC(systemConfiguration);
    }
    return getHeatPumpDesignTypeForHP(systemConfiguration);
  },
  getDesignTypeLabel(projectOrSystem: EquipmentRecommendationAPIPayload) {
    const heatPumpDesignType = ModelUtil.getHeatPumpDesignType(projectOrSystem);
    if (heatPumpDesignType !== undefined) {
      return HeatPumpTypeInfo[heatPumpDesignType].label;
    }
    return 'Furnace';
  },
  getProjectClimateType(projectOrSystem: EquipmentRecommendationAPIPayload) {
    const heatPumpDesignType = ModelUtil.getHeatPumpDesignType(projectOrSystem);
    if (heatPumpDesignType !== undefined) {
      return HeatPumpTypeInfo[heatPumpDesignType].climateType;
    }
    return undefined;
  },
  getProductTierLabel(productTier: ProductTier | undefined) {
    if (!productTier) return undefined;
    return PRODUCT_TIER_LABELS[productTier];
  },
  getProductTierLabelForRecommendation(
    project: EquipmentRecommendationAPIPayload | undefined,
    productTierLabelOverrides?: ProductTierLabelOverrides,
  ) {
    if (!project) return undefined;

    const { productTier, productTierLabelOverride } = project;
    if (productTierLabelOverride) return productTierLabelOverride;

    const customLabel = getCustomProductTierLabel(productTier, productTierLabelOverrides);
    const defaultLabel = ModelUtil.getProductTierLabel(productTier);
    return customLabel ?? defaultLabel;
  },
};

export default ModelUtil;
