import { concat } from 'lodash-es';
import { AuthenticationContext, KnownApiError } from '../../typedefs';

type RequestType = 'GET' | 'POST' | 'PUT' | 'DELETE';

const buildErrorObject = (apiError: KnownApiError) => {
  let generalErrors: string[] = [];
  let fieldErrors: { [key: string]: string[] } = {};
  const { error } = apiError;
  if (!error) {
    // This shouldn't happen
    generalErrors.push('Unknown Error!');
  } else if (!error.errors) {
    generalErrors.push(error.message);
  } else {
    const { errors } = error;
    errors.forEach((value) => {
      if (value.field) {
        fieldErrors[value.field] = concat(fieldErrors[value.field] || [], value.message);
      } else {
        generalErrors.push(value.message);
      }
    });
  }
  return {
    fieldErrors,
    generalErrors,
  };
};

function addAuthorizationBearerHeaderIfAccessToken(
  requestOptions: RequestInit,
  accessToken: string | undefined,
): RequestInit {
  if (!accessToken) return requestOptions;

  const requestHeaders = requestOptions.headers || {};
  const headers = {
    ...requestHeaders,
    Authorization: `Bearer ${accessToken}`,
  };

  return {
    ...requestOptions,
    headers,
  };
}

async function requestWithFormDataBody(
  url: string,
  method: RequestType,
  body?: FormData,
  accessToken?: string,
): Promise<any> {
  const requestOptions: RequestInit = {
    method,
    body,
    mode: 'cors',
    credentials: 'include',
  };

  const options = addAuthorizationBearerHeaderIfAccessToken(requestOptions, accessToken);
  return _request(url, options);
}

async function requestWithJSONBody(
  url: string,
  method: RequestType,
  body?: Object,
  accessToken?: string,
): Promise<any> {
  const requestOptions: RequestInit = {
    method,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
    mode: 'cors',
    credentials: 'include',
  };

  const options = addAuthorizationBearerHeaderIfAccessToken(requestOptions, accessToken);
  return _request(url, options);
}

async function _request(url: string, requestOptions: RequestInit): Promise<any> {
  const baseUrl = process.env.INTERNAL_API_SERVER || process.env.NEXT_PUBLIC_API_SERVER || 'http://localhost:3001';
  const address = `${baseUrl}/api/${url}`;
  try {
    const response = await fetch(address, requestOptions);
    if (response.status === 204) {
      return {};
    }
    const contentType = response.headers.get('content-type');
    if (!contentType?.includes('application/json')) {
      // eslint-disable-next-line prefer-promise-reject-errors
      return Promise.reject(buildErrorObject({ error: { status: response.status, message: 'Unknown Error' } }));
    }
    if (!response.ok) {
      const errorObject = buildErrorObject(await response.json());
      return Promise.reject(errorObject);
    }
    const result = await response.json();
    return result.data;
  } catch (e: any) {
    return Promise.reject(buildErrorObject({ error: { status: 500, message: 'Server Error' } }));
  }
}

export async function postData<D extends Object, R = any>(
  url: string,
  data?: D,
  authContext?: AuthenticationContext,
): Promise<R | undefined> {
  const accessToken = await authContext?.getAccessToken();
  if (authContext !== undefined && !accessToken) {
    return undefined;
  }
  return requestWithJSONBody(url, 'POST', data, accessToken);
}

export async function postFormData<R = any>(
  url: string,
  data?: FormData,
  authContext?: AuthenticationContext,
): Promise<R | undefined> {
  const accessToken = await authContext?.getAccessToken();
  if (authContext !== undefined && !accessToken) {
    return undefined;
  }
  return requestWithFormDataBody(url, 'POST', data, accessToken);
}

export async function putData<D extends Object, R = any>(
  url: string,
  data?: D,
  authContext?: AuthenticationContext,
): Promise<R | undefined> {
  const accessToken = await authContext?.getAccessToken();
  if (authContext !== undefined && !accessToken) {
    return undefined;
  }
  return requestWithJSONBody(url, 'PUT', data, accessToken);
}

export async function getData<R = any>(url: string, authContext?: AuthenticationContext): Promise<R | undefined> {
  const accessToken = await authContext?.getAccessToken();
  if (authContext !== undefined && !accessToken) {
    return undefined;
  }
  return requestWithJSONBody(url, 'GET', undefined, accessToken);
}

export async function deleteData<R = any>(url: string, authContext?: AuthenticationContext): Promise<R | undefined> {
  const accessToken = await authContext?.getAccessToken();
  if (authContext !== undefined && !accessToken) {
    return undefined;
  }
  return requestWithJSONBody(url, 'DELETE', undefined, accessToken);
}
