import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { assign, get } from 'lodash-es';
import { HYDRATE } from 'next-redux-wrapper';
import type { RootState } from '.';
import { fetchPartnerApiThunk } from '../../api/PartnerAPI';
import type {
  AdvancedFormFieldsOverrideValue,
  ContentOverride,
  FormFieldsKeys,
  FormFieldsOverride,
  FurnaceTypeContentOverrideValues,
  GlobalKeys,
  GlobalOverride,
  HeroPanelFormatContentOverrideValues,
  HideInputsContentOverrideValues,
  IQSettings,
  LayoutTypeContentOverrideValues,
  LayoutTypeTextOverrides,
  ModalOverride,
  OverridableFormFields,
  PartnerSettings,
  PartnerSettingsSimpleValueTypes,
  ProductTierLabelOverrides,
  ProjectFilterContentOverrideValues,
  ProjectGroupTitlesContentOverrides,
  ServiceTypeContentOverrideValues,
  StepName,
  StepOverride,
  UiState,
} from '../../typedefs';
import {
  DEFAULT_INSTALLER_TIMEFRAME,
  E_DEN_CONTACT_TEL_AS_ARRAY,
  EDEN_LOGO,
  EDEN_PARTNER_SLUG,
  EMPTY_DICT,
  EMPTY_LIST,
  STEP_NAMES,
} from '../../utils/constants';
import type {
  LayoutType,
  ModalType,
  RebateIncomeQualificationCriteria,
  ResultsNextButtonBehavior,
  StepType,
  YesNoType,
} from '../../utils/enums';
import ObjectUtil from '../../utils/objectUtil';
import StepUtil from '../../utils/stepUtil';
import { getLayoutType } from './formData';

const GLOBAL_CONTENT_OVERRIDES_KEY: GlobalKeys = 'GLOBAL';
const FORM_FIELDS_CONTENT_OVERRIDES_KEY: FormFieldsKeys = 'formInputOverrides';

const initialState: UiState = { partnerSlug: '' };

const slice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    initializeUiStateForPartnerSlug(state: UiState, action: PayloadAction<string>) {
      return { ...state, partnerSlug: action.payload };
    },
    initializeUiStateForEden(state, action: PayloadAction<void>) {
      return { ...state, partnerSlug: EDEN_PARTNER_SLUG, partnerDataLoaded: true };
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchPartnerApiThunk.fulfilled, (state, action) => {
      if (!action.payload) return state;
      const { partnerSlug } = state;
      const partnerSettings = {
        ...action.payload,
        hideHowItWorks: true,
        showPoweredByEden: true,
      };
      return {
        partnerSlug,
        isWhiteLabelMode: partnerSlug !== EDEN_PARTNER_SLUG,
        partnerDataLoaded: true,
        partnerSettings,
      };
    });
    builder.addCase(HYDRATE, (state, action: any) => {
      const { ui } = action?.payload || {};
      return {
        ...state,
        ...ui,
      };
    });
  },
});

// Through some strange turn of events, store.ui was undefined during the start of NextJS's SSR.
// This ensures that the we always used a defined value.
export const getUiStore = (store: RootState) => store.ui ?? {};

export const isWhiteLabelMode = (store: RootState): boolean => {
  return !!getUiStore(store).isWhiteLabelMode;
};

export const isPartnerDataLoaded = (store: RootState): boolean => {
  return !!getUiStore(store).partnerDataLoaded;
};

export const getPartnerSlug = (store: RootState): string => {
  return getUiStore(store).partnerSlug;
};

export const getPartnerSettings = (store: RootState): Partial<PartnerSettings> => {
  return getUiStore(store).partnerSettings ?? EMPTY_DICT;
};

export const getPartnerSettingWithDefault = (
  store: RootState,
  key: keyof PartnerSettings,
  defaultValue: PartnerSettingsSimpleValueTypes,
): PartnerSettingsSimpleValueTypes => {
  return get(getPartnerSettings(store), key, defaultValue) as PartnerSettingsSimpleValueTypes;
};

export const getPartnerName = (store: RootState): string | undefined => {
  return getPartnerSettings(store).name;
};

export const getPartnerLogoUrl = (store: RootState): string | undefined => {
  return getPartnerSettings(store).logoUrl;
};

export const getPartnerPhoneNumbers = (store: RootState): string[] | undefined => {
  return getPartnerSettings(store).phoneNumbers;
};

export const getPartnerLicenseNumber = (store: RootState): string | undefined => {
  return getPartnerSettings(store).licenseNumber;
};

export const getPartnerContentOverride = (store: RootState): ContentOverride | undefined => {
  return getPartnerSettings(store).contentOverride;
};

export const showInstallerLogo = createSelector(
  [getPartnerLogoUrl, isWhiteLabelMode],
  (logoUrl, isWhiteLabel): boolean => {
    if (!isWhiteLabel) return true;
    return !!logoUrl;
  },
);

export const getInstallerLogo = createSelector(
  [getPartnerLogoUrl, isWhiteLabelMode, showInstallerLogo],
  (logoUrl, isWhiteLabel, showLogo): string | undefined => {
    if (!showLogo) return undefined;
    if (!isWhiteLabel) return EDEN_LOGO;
    return logoUrl;
  },
);

export const getIsWideLogo = (store: RootState): boolean => {
  const { logoWidth, logoHeight } = getPartnerSettings(store);
  if (!logoWidth || !logoHeight) return false;
  return logoWidth >= logoHeight * 2;
};

export const showPhoneNumbers = createSelector(
  [getPartnerPhoneNumbers, isWhiteLabelMode],
  (phoneNumbers, isWhiteLabel): boolean => {
    if (!isWhiteLabel) return true;
    return !!phoneNumbers && phoneNumbers.length > 0;
  },
);

export const getInstallerPhoneNumbers = createSelector(
  [getPartnerPhoneNumbers, isWhiteLabelMode, showPhoneNumbers],
  (phoneNumbers, isWhiteLabel, showPhoneNumbers): string[] | undefined => {
    if (!showPhoneNumbers) return undefined;
    if (!isWhiteLabel) return E_DEN_CONTACT_TEL_AS_ARRAY;
    return phoneNumbers;
  },
);

export const showHeroPanelHeader = createSelector(
  [isWhiteLabelMode, showInstallerLogo, showPhoneNumbers, getPartnerLicenseNumber],
  (isWhiteLabel, showLogo, showPhones, licenseNumber): boolean => {
    if (!isWhiteLabel) return true;
    return showLogo || showPhones || !!licenseNumber;
  },
);

export const getPartnerOffersPackagedSystems = (store: RootState): boolean => {
  const partnerSettings = getPartnerSettings(store);
  const { catalogCharacteristics, offersPackagedSystems } = partnerSettings;
  return catalogCharacteristics?.offersPackagedSystems ?? offersPackagedSystems ?? false;
};

export const getPartnerOffersMultiZoneSystems = (store: RootState): boolean =>
  getPartnerSettings(store).catalogCharacteristics?.offersMultiZoneSystems ?? false;

export const getPartnerEligibleForIncomeBracketedRebates = (store: RootState): boolean =>
  getPartnerSettings(store).eligibleForIncomeBracketedRebates ?? false;

export const getPartnerPossibleIncomeQualificationCriteria = (
  store: RootState,
): RebateIncomeQualificationCriteria[] => {
  return getPartnerSettings(store).possibleIncomeQualificationCriteria || EMPTY_LIST;
};

const getStepOverridesWithSpecialCases = (
  contentOverride: ContentOverride,
  stepName: StepName,
): StepOverride | undefined => {
  const override = contentOverride[stepName];

  if (stepName === STEP_NAMES.ENERGY_INFO) {
    const hvacDataOverrides = contentOverride['HVACData' as StepName];
    return assign({}, override, hvacDataOverrides);
  }

  return override;
};

// Note: Memoized selectors that accept multiple arguments (more than the root state),
// are possible, but the arguments flow into the input selectors first. Thus,
// we forward the arguments into the result function. We forward them separately so
// they can be properly typed and so that createSelector can properly memoize.
// For reference:
// https://reselect.js.org/faq/#how-do-i-create-a-selector-that-takes-an-argument
// https://stackoverflow.com/a/77056801
function forward1stNonStoreArg<T>(store: RootState, ...args: any[]): T {
  return args[0];
}

function forward2ndNonStoreArg<T>(store: RootState, ...args: any[]): T {
  return args[1];
}

export const getStepOverrides = createSelector(
  [
    // These forwarders are specific to each field, for typing and so that createSelector can properly memoize.
    // See note above on why they exist.
    forward1stNonStoreArg<StepName | StepType | undefined>,
    forward2ndNonStoreArg<LayoutType | undefined>,
    getPartnerContentOverride,
    getLayoutType,
  ],
  (stepNameOrType, layoutType, contentOverride, formDataLayoutType): StepOverride => {
    if (!contentOverride || !stepNameOrType) {
      return EMPTY_DICT;
    }

    const effectiveLayoutType = layoutType ?? formDataLayoutType;
    const effectiveStepName = StepUtil.ensureStepName(stepNameOrType, effectiveLayoutType);
    const override = getStepOverridesWithSpecialCases(contentOverride, effectiveStepName);
    return ObjectUtil.isEmpty(override) ? EMPTY_DICT : (override as StepOverride);
  },
);

export const getModalOverrides = createSelector(
  [
    // This forwarder is specific to the field, for typing and so that createSelector can properly memoize.
    // See note above on why it exists.
    forward1stNonStoreArg<ModalType | undefined>,
    getPartnerContentOverride,
  ],
  (modalType, contentOverride): ModalOverride => {
    if (!contentOverride || !modalType) {
      return EMPTY_DICT;
    }

    const override = contentOverride[modalType];
    return ObjectUtil.isEmpty(override) ? EMPTY_DICT : (override as ModalOverride);
  },
);

export const getGlobalContentOverrides = createSelector(
  getPartnerContentOverride,
  (contentOverride): GlobalOverride => {
    if (!contentOverride) return EMPTY_DICT;

    const override = contentOverride[GLOBAL_CONTENT_OVERRIDES_KEY];
    return ObjectUtil.isEmpty(override) ? EMPTY_DICT : (override as GlobalOverride);
  },
);

export const getFormFieldsContentOverrides = createSelector(
  getPartnerSettings,
  ({ contentOverride }): FormFieldsOverride<OverridableFormFields> => {
    if (!contentOverride) return EMPTY_DICT;

    const override = contentOverride[FORM_FIELDS_CONTENT_OVERRIDES_KEY];
    return ObjectUtil.isEmpty(override) ? EMPTY_DICT : (override as FormFieldsOverride<OverridableFormFields>);
  },
);

const getFormFieldOverrideInternal = (
  store: RootState,
  fieldName: OverridableFormFields,
): AdvancedFormFieldsOverrideValue | undefined => {
  const formFieldsOverride = getFormFieldsContentOverrides(store);
  const override = formFieldsOverride[fieldName];
  if (!override) return undefined;

  if (typeof override === 'string') {
    return { label: override };
  }

  return override;
};

export const getFormFieldOverride = createSelector(
  [getFormFieldOverrideInternal],
  (formFieldsOverride): AdvancedFormFieldsOverrideValue | undefined => {
    return formFieldsOverride ? { ...formFieldsOverride } : undefined;
  },
);

export const getStepHeaderOverride = (store: RootState, stepNameOrType: StepName | StepType): string | undefined => {
  return getStepOverrides(store, stepNameOrType).stepHeader;
};

export const getStepSubHeaderOverride = (store: RootState, stepNameOrType: StepName | StepType): string | undefined => {
  return getStepOverrides(store, stepNameOrType).stepSubHeader;
};

export const getStepHideInputs = (
  store: RootState,
  stepNameOrType: StepName | StepType,
): HideInputsContentOverrideValues[] | undefined => {
  return getStepOverrides(store, stepNameOrType).hideInputs;
};

export const getStepNextButtonLabelOverride = (
  store: RootState,
  stepNameOrType: StepName | StepType,
  layoutType?: LayoutType,
): string | undefined => {
  return getStepOverrides(store, stepNameOrType, layoutType).nextButtonLabel;
};

export const getPriceDisclaimerOverride = (store: RootState): string | undefined => {
  return getStepOverrides(store, STEP_NAMES.RESULTS).priceDisclaimer;
};

export const getInstallerTimeframeOverride = (store: RootState): string => {
  return getStepOverrides(store, STEP_NAMES.RESULTS).timeframe ?? DEFAULT_INSTALLER_TIMEFRAME;
};

export const getCashPriceDisclaimerOverride = (store: RootState): string | undefined => {
  return getStepOverrides(store, STEP_NAMES.RESULTS).cashPriceDisclaimer;
};

export const getBottomDisclaimerOverride = (store: RootState): string | undefined => {
  return getStepOverrides(store, STEP_NAMES.RESULTS).bottomDisclaimer;
};

export const getProjectGroupTitlesOverride = (store: RootState): ProjectGroupTitlesContentOverrides => {
  const override = getStepOverrides(store, STEP_NAMES.RESULTS).projectGroupTitles;
  return ObjectUtil.isEmpty(override) ? EMPTY_DICT : (override as ProjectGroupTitlesContentOverrides);
};

export const getWhatsNextStepsOverride = (store: RootState): string[] | undefined => {
  return getStepOverrides(store, STEP_NAMES.RESULTS).whatsNextSteps;
};

export const getHeroPanelFormatOverride = (store: RootState): HeroPanelFormatContentOverrideValues | undefined => {
  return getStepOverrides(store, STEP_NAMES.RESULTS).heroPanelFormat;
};

export const getMultiRoomMaxNumberOfRoomsOverride = (store: RootState): number | undefined => {
  return getStepOverrides(store, STEP_NAMES.MULTI_ROOM).maxNumberOfRooms;
};

export const getIQSettings = (store: RootState): IQSettings => {
  const { iqSettings } = getPartnerSettings(store);
  return ObjectUtil.isEmpty(iqSettings) ? EMPTY_DICT : (iqSettings as IQSettings);
};

export const getDefaultZip = (store: RootState): number => {
  return getPartnerSettings(store).defaultZip ?? 94016;
};

export const getFinancingOptionsModalDescriptionOverride = (store: RootState): string | undefined => {
  const overrides = getStepOverrides(store, STEP_NAMES.RESULTS);
  return overrides.financingOptionsModalDescription;
};

export const getHideContactUsSetting = (store: RootState): boolean => {
  const overrides = getStepOverrides(store, STEP_NAMES.RESULTS);
  return overrides.hideContactUs || false;
};

export const getHideLineItemsSetting = (store: RootState): boolean => {
  const overrides = getStepOverrides(store, STEP_NAMES.RESULTS);
  return overrides.hideLineItems || false;
};

export const getSupportedServicesOverride = (store: RootState): ServiceTypeContentOverrideValues[] => {
  const overrides = getStepOverrides(store, STEP_NAMES.INTRO);
  return overrides.supportedServices || EMPTY_LIST;
};

export const getSupportedLayoutsOverride = (store: RootState): LayoutTypeContentOverrideValues[] => {
  const overrides = getStepOverrides(store, STEP_NAMES.INTRO);
  return overrides.supportedLayouts || EMPTY_LIST;
};

export const getLayoutTypeTextOverrides = (store: RootState): LayoutTypeTextOverrides => {
  const overrides = getStepOverrides(store, STEP_NAMES.INTRO);
  return ObjectUtil.isEmpty(overrides.layoutTypeTextOverrides)
    ? EMPTY_DICT
    : (overrides.layoutTypeTextOverrides as LayoutTypeTextOverrides);
};

export const getForceDuctedDeliveryVisibility = (store: RootState): boolean | undefined => {
  const overrides = getStepOverrides(store, STEP_NAMES.HOME);
  return overrides.forceDuctedDeliveryVisibility;
};

export const getNoPreviewForUnknownDuctedDelivery = (store: RootState): boolean | undefined => {
  const overrides = getStepOverrides(store, STEP_NAMES.HOME);
  return overrides.noPreviewForUnknownDuctedDelivery;
};

export const getForceExternalUnitLocationVisibility = (store: RootState): boolean | undefined => {
  const overrides = getStepOverrides(store, STEP_NAMES.HOME);
  return overrides.forceExternalUnitLocationVisibility;
};

export const getSupportedFurnaceTypesOverride = (store: RootState): FurnaceTypeContentOverrideValues[] => {
  const overrides = getStepOverrides(store, STEP_NAMES.ENERGY_INFO);
  return overrides.supportedFurnaceTypes || EMPTY_LIST;
};

export const getSupportedProjectFiltersOverride = (store: RootState): ProjectFilterContentOverrideValues[] => {
  const overrides = getStepOverrides(store, STEP_NAMES.INTRO);
  return overrides.supportedProjectFilters || EMPTY_LIST;
};

export const getShowContactFormForOverride = (store: RootState): LayoutTypeContentOverrideValues[] => {
  const overrides = getStepOverrides(store, STEP_NAMES.INTRO);
  return overrides.showContactFormFor || EMPTY_LIST;
};

export const getSecondaryTrackingIdSetting = (store: RootState): string | undefined => {
  const appSettings = getIQSettings(store);
  return appSettings.secondaryTrackerId;
};

export const getShowPhoneInputOnIntroSetting = (store: RootState): boolean => {
  const appSettings = getIQSettings(store);
  return !!appSettings.showPhoneInputOnIntro;
};

export const getShowEmailInputOnIntroSetting = (store: RootState): boolean => {
  const appSettings = getIQSettings(store);
  return !!appSettings.showEmailInputOnIntro;
};

export const getShowRepairFormOnIntroSetting = (store: RootState): boolean => {
  const appSettings = getIQSettings(store);
  return !!appSettings.showRepairFormOnIntro;
};

export const getHideFinancingTabSetting = (store: RootState): boolean => {
  const appSettings = getIQSettings(store);
  return !!appSettings.hideFinancingTab;
};

export const getShowFinancingTabByDefaultSetting = (store: RootState): boolean => {
  const appSettings = getIQSettings(store);
  return !!appSettings.showFinancingByDefault;
};

export const getResultsNextButtonBehaviorSetting = (store: RootState): ResultsNextButtonBehavior | undefined => {
  const appSettings = getIQSettings(store);
  return appSettings.resultsNextButtonBehavior;
};

export const getDefaultShowIncomeQualifiedRebatesSetting = (store: RootState): YesNoType | undefined => {
  const appSettings = getIQSettings(store);
  return appSettings.defaultShowIncomeQualifiedRebates;
};

export const getProductTierLabelOverridesSetting = (store: RootState): ProductTierLabelOverrides | undefined => {
  const appSettings = getIQSettings(store);
  return appSettings.productTierLabelOverrides;
};

export const uiActions = slice.actions;

export default slice.reducer;
