import { omit } from 'lodash-es';
import { EDEN_PARTNER_SLUG, EMPTY_JSON_OBJECT_AS_STRING } from './constants';
import WindowUtil from './windowUtil';

const SECONDS_PER_HOUR = 60 * 60;
const SECONDS_PER_DAY = SECONDS_PER_HOUR * 24;
const SECONDS_PER_MONTH = SECONDS_PER_DAY * 30;

const MAX_COOKIE_AGE_BROWSER = 400 * SECONDS_PER_DAY;

const SESSION_ID_COOKIE_NAME = 'sessId';
const FORM_UUID_COOKIE_NAME = 'uuid';
const PARTNER_UUIDS_COOKIE_NAME = 'uuids';
const USERNAME_COOKIE_NAME = 'username';
const LANDING_PAGE_REFERRER_COOKIE_NAME = 'x2elp';
const EXPERIMENT_OVERRIDES_COOKIE_NAME = 'exp';
const INTERNAL_MODE_COOKIE_NAME = 'int';
const VERSION_COOKIE_NAME = 'v';

function safeJsonParse<T>(json: string | undefined, fallback: T): T {
  try {
    return JSON.parse(json || `${fallback}`) || fallback; // NOSONAR
  } catch (error) {
    return fallback;
  }
}

function safeJsonStringify(json: any): string {
  try {
    return JSON.stringify(json || {}) || EMPTY_JSON_OBJECT_AS_STRING;
  } catch (error) {
    return EMPTY_JSON_OBJECT_AS_STRING;
  }
}

const CookieUtil = {
  setCookie: ({
    name,
    content,
    maxAgeSeconds,
    path = '/',
  }: {
    name: string;
    content: string;
    maxAgeSeconds: number | undefined; // undefined = session cookie
    path?: string;
  }) => {
    if (typeof document === 'undefined') return;

    const cookieParts = [`${name}=${encodeURIComponent(content)}`];
    if (maxAgeSeconds) {
      cookieParts.push(`max-age=${maxAgeSeconds}`);
    }
    cookieParts.push(` path=${path}`, 'secure');

    document.cookie = cookieParts.join('; ');
  },
  deleteCookie: ({ name, path = '/' }: { name: string; path?: string }) => {
    if (typeof document === 'undefined') return;
    document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${path}; secure`;
  },
  getCookie: (name: string) => {
    if (typeof document === 'undefined') return undefined;
    const row = document.cookie.split('; ').find((row) => row.startsWith(`${name}=`));
    if (!row) return undefined;
    const value = row.split('=')[1];
    if (value.indexOf('%') !== -1) return decodeURIComponent(value);
    return value;
  },
  clearUUID: (partnerSlug: string) => {
    const partnerUuids = safeJsonParse<{ [key: string]: string }>(CookieUtil.getCookie(PARTNER_UUIDS_COOKIE_NAME), {});
    const clearedPartnerUuids = omit(partnerUuids, partnerSlug);

    CookieUtil.setCookie({
      name: PARTNER_UUIDS_COOKIE_NAME,
      content: safeJsonStringify(clearedPartnerUuids),
      maxAgeSeconds: SECONDS_PER_MONTH,
    });
  },
  setUUID: (partnerSlug: string, uuid: string | undefined) => {
    if (WindowUtil.getFirstFromQueryString('uuid')) {
      return;
    }
    if (uuid) {
      const partnerUuids = safeJsonParse<{ [key: string]: string }>(
        CookieUtil.getCookie(PARTNER_UUIDS_COOKIE_NAME),
        {},
      );
      partnerUuids[partnerSlug] = uuid;

      CookieUtil.setCookie({
        name: PARTNER_UUIDS_COOKIE_NAME,
        content: safeJsonStringify(partnerUuids),
        maxAgeSeconds: SECONDS_PER_MONTH,
      });
    }
  },
  getUUID: (partnerSlug: string | undefined): string | undefined => {
    if (!partnerSlug) return undefined;

    const partnerUuids = safeJsonParse<{ [key: string]: string }>(CookieUtil.getCookie(PARTNER_UUIDS_COOKIE_NAME), {});
    const uuid = partnerUuids[partnerSlug];
    if (uuid) {
      return uuid;
    }

    if (partnerSlug === EDEN_PARTNER_SLUG) {
      const oldUuid = CookieUtil.getCookie(FORM_UUID_COOKIE_NAME);
      if (oldUuid) {
        CookieUtil.setUUID(partnerSlug, oldUuid);
      }

      return oldUuid;
    }
  },
  setUsername: (username: string) => {
    CookieUtil.setCookie({ name: USERNAME_COOKIE_NAME, content: username, maxAgeSeconds: 2 * SECONDS_PER_DAY });
  },
  clearUsername: () => {
    CookieUtil.deleteCookie({ name: USERNAME_COOKIE_NAME });
  },
  getUsername: () => CookieUtil.getCookie(USERNAME_COOKIE_NAME),
  getLandingPageReferrer: () => CookieUtil.getCookie(LANDING_PAGE_REFERRER_COOKIE_NAME),
  setSessionId: (sessionId: string) => {
    CookieUtil.setCookie({ name: SESSION_ID_COOKIE_NAME, content: sessionId, maxAgeSeconds: MAX_COOKIE_AGE_BROWSER });
  },
  getSessionId: () => CookieUtil.getCookie(SESSION_ID_COOKIE_NAME),
  setInternalMode: () => {
    CookieUtil.setCookie({ name: INTERNAL_MODE_COOKIE_NAME, content: 'true', maxAgeSeconds: MAX_COOKIE_AGE_BROWSER });
  },
  isInternalMode: () => 'true' === CookieUtil.getCookie(INTERNAL_MODE_COOKIE_NAME),
  setLoadVersion: (version: string) => {
    CookieUtil.setCookie({ name: VERSION_COOKIE_NAME, content: version, maxAgeSeconds: MAX_COOKIE_AGE_BROWSER });
  },
  getLoadVersion: () => Number(CookieUtil.getCookie(VERSION_COOKIE_NAME) || '2'), // NOSONAR
  setExperimentOverrides: (overrides: string[]) => {
    CookieUtil.setCookie({
      name: EXPERIMENT_OVERRIDES_COOKIE_NAME,
      content: safeJsonStringify(overrides),
      maxAgeSeconds: undefined,
    });
  },
  getExperimentOverrides: () => {
    const overrides: string[] = safeJsonParse<string[]>(CookieUtil.getCookie(EXPERIMENT_OVERRIDES_COOKIE_NAME), []);
    return overrides;
  },
};

export default CookieUtil;
