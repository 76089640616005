import { debounce } from 'lodash-es';
import { NextRouter } from 'next/router';
import ReactGA from 'react-ga4';
import HistoryAPI from '../api/HistoryAPI';
import type { EventContext, EventRequestAPIPayload } from '../typedefs';
import CookieUtil from './cookieUtil';
import PathUtil from './pathUtil';
import StepUtil from './stepUtil';
import StringUtil from './stringUtil';
import WindowUtil from './windowUtil';

export type SendFormEventFunction = (label: string, value?: any, context?: EventContext) => void;

type MicrosoftAdsEventStandardParameters = {
  event_category?: string;
  event_label?: string;
  event_value?: number;
};

const EDEN_ROUTING_GROUP_FOR_GTM = 'eden';
const PARTNER_ROUTING_GROUP_FOR_GTM = 'partner';

const EXPERIMENT_CATEGORY = 'experiment';

const PAGEVIEW_ACTION = 'page_view';
const QUOTE_REQUEST_ACTION = 'quote_request';
const SERVICE_REQUEST_ACTION = 'service_request';
const FORM_UPDATE_ACTION = 'form_update';
const ADDRESS_VERIFIY_UPDATE_ACTION = 'address_verify';
const PROPERTY_DATA_AUTOLOAD_UPDATE_ACTION = 'property_data_autoload';
const SELECT_PROJECT_ACTION = 'select_project';
const ASSIGN_EXPERIMENT_ACTION = 'assign_experiment';

const GOOGLE_ANALYTICS_PAGEVIEW_HITTYPE_NAME = 'pageview';
const GOOGLE_ANALYTICS_EVENT_HITTYPE_NAME = 'event';

const MICROSOFT_ADS_EVENT = 'event';
const MICROSOFT_ADS_PAGEVIEW_ACTION_NAME = 'page_view';

const DEBOUNCE_DELAY = 1000;

const historyAPI = new HistoryAPI();

const ensureSessionId = (): string => {
  const sessionId = CookieUtil.getSessionId();
  if (sessionId) return sessionId;

  const newSessionId = crypto.randomUUID();
  CookieUtil.setSessionId(newSessionId);
  return newSessionId;
};

const buildEventTrackingContext = () => {
  const browserPath = WindowUtil.getLocationPath();
  const { partnerSlug, path } = PathUtil.getPartnerSlugAndRemainderAsPath(browserPath);
  const formId = CookieUtil.getUUID(partnerSlug);
  const internal = CookieUtil.isInternalMode();

  const context = {
    partnerSlug,
    formId,
    path,
    internal,
  };

  return context;
};

const sanitizeLabelForTracking = (label: string) => {
  return StringUtil.replaceAll(label, '-', '_');
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const sendMicrosoftAdPageViewEvent = (pagePath: string, otherParameters = {}) => {
  sendMicrosoftAdEvent(MICROSOFT_ADS_PAGEVIEW_ACTION_NAME, { ...otherParameters, page_path: pagePath });
};

const sendMicrosoftAdEvent = <T extends MicrosoftAdsEventStandardParameters>(
  eventAction: string,
  eventParameters: T | {} = {},
) => {
  ensureUetqVariable();

  if (typeof window !== 'undefined' && eventAction) {
    window.uetq.push(MICROSOFT_ADS_EVENT, eventAction, eventParameters);
  }
};

const ensureUetqVariable = () => {
  if (typeof window !== 'undefined') {
    window.uetq = window.uetq || [];
  }
};

const trackingEnabled = () => {
  return WindowUtil.getCustomerUuid() === undefined;
};

const sendEventToBackend = ({
  path,
  action,
  field,
  value,
}: {
  path?: string;
  action: string;
  field?: string;
  value?: any;
}) => {
  if (!trackingEnabled()) {
    return;
  }
  const context = buildEventTrackingContext();
  const payload: EventRequestAPIPayload = {
    sessionId: ensureSessionId(),
    ...context,
    action,
    field,
    value: value !== undefined ? `${value}` : undefined,
    timestamp: new Date().toISOString(),
  };

  // path is in context, but sometimes we need to override it (ex: for modals)
  if (path) {
    payload.path = path;
  }

  // fire and forget (ignore errors)
  historyAPI.sendEvent(payload).catch(() => {});
};

const addSendToForRoutingGroup = (params: any, group: string[] | string) => {
  return {
    ...params,
    send_to: group,
  };
};

const sendEventToAllGA = (name: string, params?: any) => {
  const augmented = addSendToForRoutingGroup(params, [EDEN_ROUTING_GROUP_FOR_GTM, PARTNER_ROUTING_GROUP_FOR_GTM]);
  ReactGA.event(name, augmented);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const sendEventToPartnerOnlyGA = (name: string, params?: any) => {
  const augmented = addSendToForRoutingGroup(params, PARTNER_ROUTING_GROUP_FOR_GTM);
  ReactGA.event(name, augmented);
};

const sendEventToEdenOnlyGA = (name: string, params?: any) => {
  const augmented = addSendToForRoutingGroup(params, EDEN_ROUTING_GROUP_FOR_GTM);
  ReactGA.event(name, augmented);
};

const sendSimpleEvent = (
  name: string,
  config: { gaSender: (name: string, params?: any) => void } = { gaSender: sendEventToAllGA },
) => {
  if (!trackingEnabled()) {
    return;
  }

  config.gaSender(name);
  sendEventToBackend({ action: name });
};

const formUpdate = (label: string, value?: any) => {
  if (!label || !trackingEnabled()) {
    return;
  }
  label = sanitizeLabelForTracking(label);
  const numberValue = typeof value === 'number' ? value : undefined;

  sendEventToEdenOnlyGA(FORM_UPDATE_ACTION, { label, value: numberValue });

  sendEventToBackend({
    action: FORM_UPDATE_ACTION,
    field: label,
    value,
  });
};

const quoteRequest = () => {
  sendSimpleEvent(QUOTE_REQUEST_ACTION);
};

const serviceRequest = () => {
  sendSimpleEvent(SERVICE_REQUEST_ACTION);
};

const addressVerify = () => {
  sendSimpleEvent(ADDRESS_VERIFIY_UPDATE_ACTION);
};

const propertyDataAutoload = () => {
  sendSimpleEvent(PROPERTY_DATA_AUTOLOAD_UPDATE_ACTION, { gaSender: sendEventToEdenOnlyGA });
};

const selectProjectEvent = () => {
  sendSimpleEvent(SELECT_PROJECT_ACTION, { gaSender: sendEventToEdenOnlyGA });
};

const pageView = (page: string, title: string, iqPath?: string) => {
  if (!trackingEnabled()) {
    return;
  }

  if (iqPath) {
    page += `/${StepUtil.urlFormat(iqPath)}`;
    title += ` (${iqPath})`;
  }

  const origin = WindowUtil.getLocationOrigin();

  ReactGA.send({
    hitType: GOOGLE_ANALYTICS_PAGEVIEW_HITTYPE_NAME,
    location: `${origin}${page}`,
    page,
    title,
  });

  // Check if the path / page is right
  sendEventToBackend({
    action: PAGEVIEW_ACTION,
    path: page,
  });
};

const pageViewInternal = () => {
  sendEventToBackend({
    action: PAGEVIEW_ACTION,
  });
};

const assignedToExperiment = (experimentInfo: any) => {
  ReactGA.gtag(GOOGLE_ANALYTICS_EVENT_HITTYPE_NAME, ASSIGN_EXPERIMENT_ACTION, {
    event_category: EXPERIMENT_CATEGORY,
    ...experimentInfo,
  });
};

const ensureWindowDataLayer = () => {
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || [];
  }
};

const pushToWindowDataLayer = (data: any) => {
  if (typeof window !== 'undefined' && data) {
    ensureWindowDataLayer();
    window.dataLayer.push(data);
  }
};

const getConfigurationForReactGAInitialization = (trackingId: string, routingGroup: string) => {
  return { trackingId, gtagOptions: { groups: routingGroup } };
};

const initalize = (secondaryTrackerId: string | null | undefined): string => {
  const sessionId = ensureSessionId();
  const enabled = trackingEnabled();

  const trackers = [];
  const primaryTrackerId = process.env.NEXT_PUBLIC_GA_ID;

  if (enabled) {
    if (primaryTrackerId) {
      const config = getConfigurationForReactGAInitialization(primaryTrackerId, EDEN_ROUTING_GROUP_FOR_GTM);
      trackers.push(config);
    }

    if (secondaryTrackerId) {
      const config = getConfigurationForReactGAInitialization(secondaryTrackerId, PARTNER_ROUTING_GROUP_FOR_GTM);
      trackers.push(config);
      pushToWindowDataLayer({ secondaryTrackerId });
    }
  }

  if (trackers.length > 0) {
    ReactGA.initialize(trackers);
  }

  return sessionId;
};

const handleRouteChangeComplete = () => {
  pageViewInternal();
};

const configureRouterToSendPageViewEvents = (router: NextRouter): (() => void) => {
  router.events.on('routeChangeComplete', handleRouteChangeComplete);
  return () => {
    router.events.off('routeChangeComplete', handleRouteChangeComplete);
  };
};

const getSessionId = () => {
  return ensureSessionId();
};

const TrackingUtil = {
  getSessionId,
  formUpdate,
  quoteRequest,
  serviceRequest,
  selectProjectEvent,
  addressVerify,
  propertyDataAutoload,
  pageView,
  pageViewInternal,
  assignedToExperiment,
  initalize,
  createDebouncedFormUpdateFunc: () => debounce<SendFormEventFunction>(formUpdate, DEBOUNCE_DELAY),
  configureRouterToSendPageViewEvents,
};

export default TrackingUtil;
