import { postData } from '../redux/fetchService/baseFetch';
import { FormFields, RebatesAPIPayload, type AuthenticationContext, type RebatesAPIRequest } from '../typedefs';
import { createApiThunk } from './utils/ApiThunkCreator';

const setNumberIfPresent = (value: string) => (value !== '' && value !== undefined ? Number(value) : undefined);

export class RebatesAPI {
  fetchRebates = async (params: {
    formData: Pick<FormFields, 'zipCode' | 'furnaceType' | 'layoutType' | 'ductedDelivery'>;
    partnerSlug: string;
    uuid: string | undefined;
    formVersion: number | undefined;
  }): Promise<RebatesAPIPayload | undefined> => {
    const { formData, partnerSlug, uuid, formVersion } = params;
    const { zipCode: zipFromForm, furnaceType, layoutType, ductedDelivery } = formData;

    const zipCode = setNumberIfPresent(zipFromForm);
    if (!zipCode || !uuid || !formVersion) {
      return undefined;
    }

    return postData<RebatesAPIRequest, RebatesAPIPayload>('rebates', {
      uuid,
      partnerSlug,
      zipCode,
      layoutType,
      furnaceType,
      ductedDelivery,
      formVersion,
    });
  };

  fetchRebatesForRecommendations = async (
    params: {
      formData: Pick<FormFields, 'zipCode' | 'furnaceType' | 'layoutType' | 'ductedDelivery'>;
      recommendationIds: number[];
    },
    authContext?: AuthenticationContext,
  ): Promise<RebatesAPIPayload | undefined> => {
    const { formData, recommendationIds } = params;
    const { zipCode: zipFromForm, furnaceType, layoutType, ductedDelivery } = formData;

    const zipCode = setNumberIfPresent(zipFromForm);
    if (!zipCode) {
      return undefined;
    }

    return postData<any, RebatesAPIPayload>(
      'rebates/forRecommendations',
      {
        zipCode,
        layoutType,
        furnaceType,
        ductedDelivery,
        recommendationIds,
      },
      authContext,
    );
  };
}

export const fetchRebatesApiThunk = createApiThunk('rebatesApi/get', new RebatesAPI().fetchRebates);
