import { createSelector, createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { concat, find, reduce, round } from 'lodash-es';
import type { RootState } from '.';
import {
  createProjectApiThunk,
  reloadProjectApiThunk,
  saveProjectApiThunk,
  submitProjectApiThunk,
} from '../../api/ProjectsAPI';
import type { Equipment, EquipmentRecommendationAPIPayload, ProjectState } from '../../typedefs';
import { EMPTY_DICT, EMPTY_LIST, MONTHS_PER_YEAR, QUARTER_TONS_PER_TON, SAVING_LIFESPAN } from '../../utils/constants';
import ModelUtil from '../../utils/modelUtil';

const initialState: ProjectState = {
  recommendations: [],
  numLoading: 0,
  initialized: false,
};

const slice = createSlice({
  name: 'projects',
  initialState,
  reducers: {
    setUUID(state, action: PayloadAction<string>) {
      return { ...state, uuid: action.payload };
    },
    setInitialized(state, action: PayloadAction<boolean>) {
      return { ...state, initialized: action.payload };
    },
    clearProjects(state) {
      return {
        ...state,
        recommendations: [],
        selectedId: undefined,
        formVersion: undefined,
      };
    },
    selectProjectId(state, action: PayloadAction<number>) {
      return { ...state, selectedId: action.payload };
    },
    onLoadingStart(state) {
      return { ...state, numLoading: state.numLoading + 1 };
    },
    onLoadingComplete(state) {
      return { ...state, numLoading: state.numLoading - 1 };
    },
  },
  extraReducers(builder) {
    builder.addCase(saveProjectApiThunk.pending, (state, action) => {
      state.pendingSaveRequestId = action.meta.requestId;
    });
    builder.addCase(saveProjectApiThunk.rejected, (state, action) => {
      if (state.pendingSaveRequestId !== action.meta.requestId) {
        return;
      }
      state.pendingSaveRequestId = undefined;
    });
    builder.addCase(saveProjectApiThunk.fulfilled, (state, action) => {
      if (!action.payload) return state;

      const { pendingSaveRequestId, selectedId, formVersion: currentFormVersion } = state;
      if (pendingSaveRequestId !== action.meta.requestId) {
        return;
      }
      const { payload } = action;
      const { recommendations, uuid, designTemps, formVersion } = payload;

      if (currentFormVersion && formVersion && currentFormVersion >= formVersion) {
        return;
      }

      const selectedRecommendation = find(
        recommendations,
        (recommendation) => recommendation.recommendationId === selectedId,
      );

      return {
        ...state,
        pendingSaveRequestId: undefined,
        recommendations: recommendations ?? EMPTY_LIST,
        formVersion,
        designTemps,
        selectedId: selectedRecommendation?.recommendationId,
        uuid: uuid || state.uuid,
      };
    });
    builder.addCase(createProjectApiThunk.fulfilled, (state, action) => {
      if (!action.payload) return state;
      return {
        ...state,
        uuid: action.payload.uuid || state.uuid,
      };
    });
    builder.addCase(submitProjectApiThunk.pending, (state, action) => {
      state.pendingSubmitRequestId = action.meta.requestId;
    });
    builder.addCase(reloadProjectApiThunk.fulfilled, (state, action) => {
      if (!action.payload) return state;
      const { selectedProjectId } = action.payload;
      return {
        ...state,
        selectedId: selectedProjectId,
      };
    });
    builder.addMatcher(isAnyOf(submitProjectApiThunk.fulfilled, submitProjectApiThunk.rejected), (state, action) => {
      if (state.pendingSubmitRequestId !== action.meta.requestId) {
        return;
      }
      state.pendingSubmitRequestId = undefined;
    });
  },
});

export const projectActions = slice.actions;

export const getInitialized = (store: RootState) => store.projects.initialized;
export const isLoading = (store: RootState) => store.projects.numLoading > 0;
export const isSubmitting = (store: RootState) => !!store.projects.pendingSubmitRequestId;
export const isSaving = (store: RootState) => !!store.projects.pendingSaveRequestId;

export const getRecommendedProjects = (store: RootState) => store.projects.recommendations ?? EMPTY_LIST;
export const getRecommendedProjectsFormVersion = (store: RootState) => store.projects.formVersion;
export const getRecommendedHeatAndCoolProjects = createSelector(
  getRecommendedProjects,
  (projects) => projects.filter(isHeatAndCoolRecommendation) || EMPTY_LIST,
);
export const getRecommendedACs = createSelector(
  getRecommendedProjects,
  (projects) => projects.filter(isACOnlyRecommendation) || EMPTY_LIST,
);
export const getRecommendedFurnaces = createSelector(
  getRecommendedProjects,
  (projects) => projects.filter(isFurnaceRecommendation) || EMPTY_LIST,
);

export const getProjectById = (store: RootState, recommendationId: number) =>
  getRecommendedProjects(store).find((p) => p.recommendationId === recommendationId);

export const getSelectedProjectId = (store: RootState) => store.projects.selectedId;

export const getSelectedProject: (store: RootState) => EquipmentRecommendationAPIPayload | undefined = (
  store: RootState,
) => {
  const selectedProjectId = getSelectedProjectId(store);
  if (selectedProjectId) {
    return getProjectById(store, selectedProjectId);
  }
};

export const getTonnage = (project?: EquipmentRecommendationAPIPayload) => {
  if (!project) return undefined;
  const { systemConfiguration, furnace } = project;
  const quarterTons = systemConfiguration?.quarterTons ?? furnace?.quarterTons;
  if (!quarterTons) return undefined;
  return quarterTons / QUARTER_TONS_PER_TON;
};
export const getUUID = (store: RootState) => store.projects.uuid;
export const getNumUnits = (project?: EquipmentRecommendationAPIPayload) => project && project.numUnits;

const getWarranty = (project?: EquipmentRecommendationAPIPayload, model?: Equipment) => {
  if (!project || !model) return undefined;
  const warranties = project.installerWarranties || [];
  return warranties.find((warranty) => warranty.brand.name === model.brand.name);
};

export const getProjectSystemConfigurationId = (project?: EquipmentRecommendationAPIPayload) => {
  if (!project) return undefined;
  return project.systemConfiguration?.id;
};

export const getProjectId = (project?: EquipmentRecommendationAPIPayload) => {
  return project && project.recommendationId;
};

export const getEquipmentLifetimeInMonths = (project?: EquipmentRecommendationAPIPayload) => {
  if (!project) return undefined;
  return project.equipmentLifetime * MONTHS_PER_YEAR;
};

export const getPartsWarranty = (project?: EquipmentRecommendationAPIPayload, model?: Equipment) => {
  const warranty = getWarranty(project, model);
  return warranty?.partsWarranty;
};

export const getLaborWarranty = (project?: EquipmentRecommendationAPIPayload) => {
  const warranty = getWarranty(project, project && project.systemConfiguration?.externalModel);
  return warranty?.laborWarranty;
};

export const getDesignTemps = (store: RootState): { heating?: number; cooling?: number } =>
  store.projects.designTemps ?? EMPTY_DICT;

export const getDesignTemp = (store: RootState) => getDesignTemps(store).heating;

export const getIndoorUnitCount = (project: EquipmentRecommendationAPIPayload) => {
  const { internalModelCounts } = project;
  const count = reduce(internalModelCounts, (sum, imc) => sum + imc.numUnits, 0);
  return count;
};

// TODO calculate this value on the backend
export const getPayoffPeriod = (project?: EquipmentRecommendationAPIPayload) => {
  if (!project) return undefined;
  const { utilitySavings, total } = project;
  if (!utilitySavings || total === undefined) return undefined;

  const yearsToPayoff = total / (utilitySavings / SAVING_LIFESPAN);
  if (yearsToPayoff > SAVING_LIFESPAN) {
    return undefined;
  }
  return round(yearsToPayoff);
};

export const getProjectPhotos = (project?: EquipmentRecommendationAPIPayload) => {
  if (!project) return EMPTY_LIST;

  const { systemConfiguration, furnace, internalModelOptions } = project;
  if (!systemConfiguration) {
    return furnace?.photos || EMPTY_LIST;
  }
  const { photos: systemPhotos } = systemConfiguration;
  const { photos: imoPhotos } = internalModelOptions || {};
  return concat(imoPhotos || [], systemPhotos || []) || EMPTY_LIST;
};

export const getProjectNoise = (project: EquipmentRecommendationAPIPayload) => {
  return project.systemConfiguration?.externalModel.noiseMin;
};

export const getProjectTotal = (project: EquipmentRecommendationAPIPayload): number => {
  return project.total ?? 0;
};

export const getProjectInstallerId = (project: EquipmentRecommendationAPIPayload) => {
  return project?.priceSnapshot.installer.id;
};

export const isACOnlyRecommendation = (recommendation: EquipmentRecommendationAPIPayload) => {
  const { systemConfiguration } = recommendation;
  if (!systemConfiguration || ModelUtil.isSystemWithFurnace(systemConfiguration)) return false;
  return ModelUtil.isAcSystem(systemConfiguration);
};

export const isHeatPumpRecommendation = (recommendation: EquipmentRecommendationAPIPayload) => {
  const { systemConfiguration } = recommendation;
  if (!systemConfiguration) return false;
  return !ModelUtil.isAcSystem(systemConfiguration);
};

export const isHeatAndCoolRecommendation = (recommendation: EquipmentRecommendationAPIPayload) => {
  if (isHeatPumpRecommendation(recommendation)) return true;

  const { systemConfiguration } = recommendation;
  return ModelUtil.isSystemWithFurnace(systemConfiguration);
};

export const isFurnaceRecommendation = (recommendation: EquipmentRecommendationAPIPayload) => {
  return !!recommendation.furnace;
};

export default slice.reducer;
