import type { Dictionary } from 'lodash';
import { filter, first, flatMap, flatten, groupBy, includes, isEmpty, sortBy, take, values } from 'lodash-es';
import type { FormFields, Rebate, RebateBucket, RebatesByRecommendationId } from '../typedefs';
import { REBATE_DISCLAIMER, REBATE_NO_UTILITY_ID } from './constants';
import { COST_DISCOUNT_LABELS, RebateIncomeQualificationCriteria, type CostDiscountType } from './enums';
import FilterUtil from './filterUtil';
import StringUtil from './stringUtil';

// Since the legit AMI values of SelectedHudAmi and RebateIncomeQualificationCriteria match, we can basically directly
// convert the input to the criteria type.

function sortRebatesByAmount(rebates: Rebate[]) {
  return sortBy(rebates, (rebate) => -1 * rebate.amount);
}

function pickNthLargest(rebates: Rebate[], n: number = 1): Rebate[] {
  const sortedRebates = sortRebatesByAmount(rebates);
  return take(sortedRebates);
}

function pickLargest(rebates: Rebate[]): Rebate | undefined {
  return first(pickNthLargest(rebates));
}

function pickLargestRebatesByName(rebates: Rebate[]): Rebate[] {
  const rebatesByDisplayName = groupBy(rebates, (rebate) => rebate.name);
  const largestRebates = flatMap(values(rebatesByDisplayName), (rebates) => pickLargest(rebates));
  return filter(largestRebates) as Rebate[];
}

function determineBucketKey(bucket: RebateBucket | undefined): string {
  if (!bucket) return 'noBucket';
  const { incomeQualified, incomeQualificationCriteria, electricUtilityProviderIds } = bucket;
  return `${first(electricUtilityProviderIds)}|${incomeQualified ? 'iq' : 'noniq'}|${first(incomeQualificationCriteria)}`;
}

function returnValueIfIsInList<T>(value: T | undefined, list: T[]) {
  return includes(list, value) ? value : undefined;
}

function determineBucketKeyForFormData(
  formData: FormFields,
  electricUtilityProviderIdsInRebates: string[],
  incomeQualificationCriteriaInRebates: RebateIncomeQualificationCriteria[],
) {
  const { showIncomeQualifiedRebates, selectedHudAmi, selectedElectricUtilityProviderId } = formData;

  const electricUtilityProviderId = returnValueIfIsInList(
    selectedElectricUtilityProviderId,
    electricUtilityProviderIdsInRebates,
  );

  const incomeQualificationCriteria = returnValueIfIsInList<RebateIncomeQualificationCriteria>(
    selectedHudAmi as any,
    incomeQualificationCriteriaInRebates,
  );
  const effectiveIncomeQualificationCriteria = showIncomeQualifiedRebates ? incomeQualificationCriteria : undefined;

  const bucket: RebateBucket = {
    electricUtilityProviderIds: [electricUtilityProviderId ?? REBATE_NO_UTILITY_ID],
    incomeQualified: !!showIncomeQualifiedRebates,
    incomeQualificationCriteria: [effectiveIncomeQualificationCriteria as any],
  };

  return determineBucketKey(bucket);
}

const RebateUtil = {
  sortRebatesByAmount,
  pickLargest,
  pickLargestRebatesByName,

  generatePresentableDescription(rebate: Rebate, config: { omitUrl?: boolean; overrideAmount?: number } = {}) {
    const { amount, description, url, incomeQualificationUrl } = rebate;
    const effectiveAmount = config.overrideAmount ? config.overrideAmount : amount;
    const amountStr = StringUtil.formatCurrency(effectiveAmount);

    const descriptionCopy = `${description}`;
    let updated = descriptionCopy.replace(/\{amount\}/g, amountStr);
    updated = `${updated}\n\n${REBATE_DISCLAIMER}`;

    if (url && !config?.omitUrl) {
      updated = `${updated}\n\n For details and requirements, visit: ${url}`;
    }

    if (incomeQualificationUrl && !config?.omitUrl) {
      updated = `${updated}\n\n For details on eligibility and income thresholds, visit: ${url}`;
    }

    return updated;
  },

  filterRebatesForProject(
    bucketedRebates: Dictionary<Rebate[]>,
    electricUtilityProviderIdsInRebates: string[],
    incomeQualificationCriteriaInRebates: RebateIncomeQualificationCriteria[],
    formData: FormFields,
  ) {
    if (!bucketedRebates || isEmpty(bucketedRebates)) return [];

    const bucketKey = determineBucketKeyForFormData(
      formData,
      electricUtilityProviderIdsInRebates,
      incomeQualificationCriteriaInRebates,
    );

    const matchingRebates = bucketedRebates[bucketKey];
    return sortRebatesByAmount(matchingRebates);
  },

  groupRebatesByIncomeQualification(rebates: Rebate[] | undefined): {
    nonIqRebates: Rebate[];
    iqRebates: Rebate[];
    incomeBracketedRebates: Rebate[];
  } {
    if (!rebates || isEmpty(rebates)) return { iqRebates: [], nonIqRebates: [], incomeBracketedRebates: [] };

    const { matches: iqRebates, nonmatches: nonIqRebates } = FilterUtil.splitListByFilterMatch(
      rebates,
      (rebate) => !!rebate.bucket?.incomeQualified || false,
    );

    const filteredNonIqRebates = pickLargestRebatesByName(nonIqRebates);
    const sortedFilteredNonIqRebates = sortRebatesByAmount(filteredNonIqRebates);

    const { matches: omitIncomeBracketedRebates, nonmatches: incomeBracketedRebates } =
      FilterUtil.splitListByFilterMatch(iqRebates, (rebate) => {
        const { incomeQualificationCriteria } = rebate.bucket || {};
        return isEmpty(incomeQualificationCriteria);
      });

    const filteredIqRebates = pickLargestRebatesByName(omitIncomeBracketedRebates);
    const sortedFilteredIqRebates = sortRebatesByAmount(filteredIqRebates);

    return { iqRebates: sortedFilteredIqRebates, nonIqRebates: sortedFilteredNonIqRebates, incomeBracketedRebates };
  },

  getRebateTotal(rebates?: Rebate[]) {
    if (!rebates || isEmpty(rebates)) return 0;
    return rebates.reduce((total, rebate) => total + rebate.amount, 0);
  },

  getRebateGroupLabel(rebates: Rebate[], titleCase: boolean = false): string {
    if (!rebates || isEmpty(rebates)) return '';

    const labels: string[] = [];
    Object.keys(COST_DISCOUNT_LABELS).forEach((type) => {
      const label = COST_DISCOUNT_LABELS[type as CostDiscountType];
      if (rebates.find((rebate) => rebate.type === type))
        labels.push(`${titleCase ? StringUtil.toTitleCase(label) : label}s`);
    });

    if (labels.length < 2) {
      return labels.join('');
    }

    labels[labels.length - 1] = `and ${labels[labels.length - 1]}`;

    if (labels.length === 2) {
      return labels.join(' ');
    }
    return labels.join(', ');
  },

  findLargestRebates(rebatesByRecommendationId: RebatesByRecommendationId): Rebate[] {
    if (isEmpty(rebatesByRecommendationId)) return [];

    const allRebates = flatten(values(rebatesByRecommendationId));
    const filteredRebates = filter(allRebates, (rebate) => {
      // Exclude income-bracketed rebates
      const { incomeQualificationCriteria } = rebate.bucket || {};
      if (isEmpty(incomeQualificationCriteria)) return true;
      return false;
    });

    const { matches: iqRebates, nonmatches: nonIqRebates } = FilterUtil.splitListByFilterMatch(
      filteredRebates,
      (rebate) => !!rebate.incomeQualified || false,
    );

    const largestIqRebates = pickLargestRebatesByName(iqRebates);
    const largestNonIqRebates = pickLargestRebatesByName(nonIqRebates);
    const largestRebates = filter([first(largestIqRebates), ...take(largestNonIqRebates, 4)]) as Rebate[];

    return pickLargestRebatesByName(largestRebates);
  },

  determineBucketKey,
};

export default RebateUtil;
