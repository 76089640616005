import { createSelector, createSlice } from '@reduxjs/toolkit';
import { isEmpty, mapValues } from 'lodash-es';
import type { RootState } from '.';
import { fetchFinancingApiThunk } from '../../api/FinancingAPI';
import type { EquipmentRecommendationAPIPayload, Financing, FinancingState } from '../../typedefs';
import { getProjectInstallerId } from './projects';

const initialState: FinancingState = {
  utilityLoans: [],
  defaultLoans: [],
  installerFinancing: {},
};

const slice = createSlice({
  name: 'financing',
  initialState,
  reducers: {
    clearFinancing(state) {
      return initialState;
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchFinancingApiThunk.fulfilled, (state, action) => {
      const { payload } = action;
      if (!payload) {
        return;
      }

      const { formVersion } = payload;
      const { formVersion: currentFormVersion } = state;
      if (currentFormVersion && formVersion && currentFormVersion >= formVersion) {
        return;
      }

      return payload;
    });
  },
});

export const getFinancingForProject = (store: RootState, project: EquipmentRecommendationAPIPayload): Financing[] => {
  const { utilityLoans, defaultLoans, installerFinancing } = store.financing;

  const installerId = getProjectInstallerId(project);
  if (installerId && installerFinancing) {
    const installerFinancingForInstaller = installerFinancing[installerId];
    if (installerFinancingForInstaller && !isEmpty(installerFinancingForInstaller.loans)) {
      return [...utilityLoans, ...installerFinancingForInstaller.loans];
    }
  }

  return [...utilityLoans, ...defaultLoans];
};

const getFinancing = (store: RootState) => store.financing;

export const getInstallerAndNoInstallerFinancing = createSelector(getFinancing, (financing) => {
  const { utilityLoans, defaultLoans, installerFinancing: financingByInstallerId } = financing;

  const noInstallerFinancing = [...utilityLoans, ...defaultLoans];
  const enhancedInstallerFinancing = mapValues(financingByInstallerId, (installerFinancing) => {
    return [...utilityLoans, ...installerFinancing.loans];
  });

  return {
    installerFinancing: enhancedInstallerFinancing,
    noInstallerFinancing,
  };
});

export const financingActions = slice.actions;

export default slice.reducer;
