import { postData } from '../redux/fetchService/baseFetch';
import { FinancingAPIPayload, FormFields, type FinancingAPIRequest } from '../typedefs';
import { createApiThunk } from './utils/ApiThunkCreator';

const setNumberIfPresent = (value: string) => (value !== '' ? Number(value) : undefined);

class FinancingAPI {
  fetchFinancing = async (params: {
    formData: Pick<FormFields, 'zipCode' | 'furnaceType' | 'layoutType' | 'ductedDelivery'>;
    partnerSlug: string;
    uuid: string | undefined;
    formVersion: number | undefined;
  }): Promise<FinancingAPIPayload | undefined> => {
    const { formData, partnerSlug, uuid, formVersion } = params;
    const { zipCode: zipFromForm } = formData;

    const zipCode = setNumberIfPresent(zipFromForm);
    if (!zipCode || !uuid || !formVersion) {
      return undefined;
    }

    return postData<FinancingAPIRequest, FinancingAPIPayload>('financing', {
      uuid,
      partnerSlug,
      zipCode,
      formVersion,
    });
  };
}

export const fetchFinancingApiThunk = createApiThunk('financingApi/get', new FinancingAPI().fetchFinancing);
