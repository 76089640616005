import { includes, map, reject, uniq } from 'lodash-es';
import { StepName, type StepContext } from '../typedefs';
import { INSTANT_QUOTE_PATH, STEP_NAMES } from './constants';
import EnumUtil from './enumUtil';
import { LayoutType, STEP_LABEL, StepType } from './enums';
import StringUtil from './stringUtil';

const SINGLE_ROOM_STEPS = [StepType.LOCATION, StepType.ROOM_INFO, StepType.RESULTS];
const MULTIPLE_ROOM_STEPS = [StepType.LOCATION, StepType.ROOM_INFO, StepType.ENERGY_INFO, StepType.RESULTS];

const getWholeHomeSteps = (showBuildingInfoStep: boolean) => {
  if (showBuildingInfoStep) {
    return [StepType.LOCATION, StepType.BUILDING_INFO, StepType.ENERGY_INFO, StepType.RESULTS];
  }
  return [StepType.LOCATION, StepType.ENERGY_INFO, StepType.RESULTS];
};

const hasNoPostIntroSteps = (stepContext: Pick<StepContext, 'layoutType' | 'showContactForm'>): boolean => {
  const { layoutType, showContactForm } = stepContext;
  return (
    layoutType === undefined ||
    showContactForm ||
    includes([LayoutType.UNKNOWN, LayoutType.BOILER, LayoutType.REPLACE_EXISTING], layoutType)
  );
};

const urlFormat = (str: string) => {
  return `${StringUtil.replaceAll(str, ' ', '-')}`;
};

const getAllPossibleStepPaths = () => {
  return map(EnumUtil.getIdsForEnum(StepType), (stepType) => urlFormat(STEP_LABEL[stepType]));
};

const stepUrl = (partnerSlug: string, stepType: StepType, path?: string) => {
  const url = `/${partnerSlug}/${urlFormat(STEP_LABEL[stepType])}`;
  if (!path) {
    return url;
  }
  return `${url}/${urlFormat(path)}`;
};

const getPath = (layoutType?: LayoutType) => {
  switch (layoutType) {
    case LayoutType.SINGLE_ROOM:
      return INSTANT_QUOTE_PATH.SINGLE_ROOM;
    case LayoutType.MULTIPLE_ROOMS:
      return INSTANT_QUOTE_PATH.MULTI_ROOM;
    case LayoutType.WHOLE_HOME:
      return INSTANT_QUOTE_PATH.WHOLE_HOME;
    default:
      return undefined;
  }
};

const getPathsForStep = (
  step: StepType,
  stepContext: Pick<StepContext, 'showContactForm' | 'showBuildingInfoStep' | 'showHouseholdStep'>,
): LayoutType[] => {
  if (step === StepType.INTRO) {
    return [];
  }
  const layouts = EnumUtil.getIdsForEnum(LayoutType);
  return layouts.filter((layout) => {
    if (hasNoPostIntroSteps({ ...stepContext, layoutType: layout })) return false;
    const stepsInLayout = getPostIntroSteps({ ...stepContext, layoutType: layout });
    return stepsInLayout.includes(step);
  });
};

const getPathFromUrl = (pathname: string | undefined) => {
  if (!pathname) return undefined;

  const paths = pathname.split('/');
  // We expect to get /<partner>/<step name>/<path name> if there's a path.
  if (paths.length !== 4) {
    return undefined;
  }
  const lastVal = paths[3];
  switch (lastVal) {
    case urlFormat(INSTANT_QUOTE_PATH.SINGLE_ROOM):
      return LayoutType.SINGLE_ROOM;
    case urlFormat(INSTANT_QUOTE_PATH.MULTI_ROOM):
      return LayoutType.MULTIPLE_ROOMS;
    case urlFormat(INSTANT_QUOTE_PATH.WHOLE_HOME):
    default:
      return LayoutType.WHOLE_HOME;
  }
};

const getPostIntroStepsForLayout = (
  stepContext: Pick<StepContext, 'layoutType' | 'showContactForm' | 'showBuildingInfoStep'>,
) => {
  const { layoutType, showBuildingInfoStep } = stepContext;
  if (layoutType === undefined || hasNoPostIntroSteps(stepContext)) return [];
  switch (layoutType) {
    case LayoutType.SINGLE_ROOM:
      return SINGLE_ROOM_STEPS;
    case LayoutType.MULTIPLE_ROOMS:
      return MULTIPLE_ROOM_STEPS;
    case LayoutType.WHOLE_HOME:
    default:
      return getWholeHomeSteps(showBuildingInfoStep);
  }
};

const getPostIntroSteps = (
  stepContext: Pick<StepContext, 'layoutType' | 'showContactForm' | 'showBuildingInfoStep' | 'showHouseholdStep'>,
) => {
  const { layoutType, showHouseholdStep } = stepContext;
  if (layoutType === undefined || hasNoPostIntroSteps(stepContext)) return [];
  const steps = getPostIntroStepsForLayout(stepContext);
  if (!showHouseholdStep) return steps;

  const start = steps.slice(0, -1);
  const end = steps[steps.length - 1];
  return [...start, StepType.HOUSEHOLD, end];
};

const getSteps = (
  stepContext: Pick<StepContext, 'layoutType' | 'showContactForm' | 'showBuildingInfoStep' | 'showHouseholdStep'>,
) => {
  return [StepType.INTRO, ...getPostIntroSteps(stepContext)];
};

const getStepURLs = (
  stepContext: Pick<
    StepContext,
    'partnerSlug' | 'layoutType' | 'showContactForm' | 'showBuildingInfoStep' | 'showHouseholdStep'
  >,
) => {
  const { partnerSlug, layoutType } = stepContext;
  const path = getPath(layoutType);
  return [
    stepUrl(partnerSlug, StepType.INTRO),
    ...getPostIntroSteps(stepContext).map((step) => stepUrl(partnerSlug, step, path)),
  ];
};

const getStepURLsForAllLayoutTypes = (
  stepContext: Pick<StepContext, 'partnerSlug' | 'showContactForm' | 'showBuildingInfoStep' | 'showHouseholdStep'>,
) => {
  const layoutTypes = reject(EnumUtil.getIdsForEnum(LayoutType), (x) => {
    return hasNoPostIntroSteps({ ...stepContext, layoutType: x });
  });

  const urls = map(layoutTypes, (layoutType) => {
    const layoutUrls = getStepURLs({ ...stepContext, layoutType });
    return layoutUrls;
  }).flat();

  return uniq(urls);
};

const getNextStepUrl = (
  stepContext: Pick<
    StepContext,
    'partnerSlug' | 'layoutType' | 'showContactForm' | 'showBuildingInfoStep' | 'showHouseholdStep'
  >,
  currentStep: StepType,
) => {
  const steps = getSteps(stepContext);
  const currentStepNum = getCurrentStepNum(stepContext, currentStep);
  if (steps.length === currentStepNum) {
    return undefined;
  }
  return getStepURLs(stepContext)[currentStepNum];
};

const getPrevStepUrl = (
  stepContext: Pick<
    StepContext,
    'partnerSlug' | 'layoutType' | 'showContactForm' | 'showBuildingInfoStep' | 'showHouseholdStep'
  >,
  currentStep: StepType,
) => {
  const currentStepNum = getCurrentStepNum(stepContext, currentStep);
  if (currentStepNum <= 1) {
    return undefined;
  }
  return getStepURLs(stepContext)[currentStepNum - 2];
};

const getCurrentStepNum = (
  stepContext: Pick<StepContext, 'layoutType' | 'showContactForm' | 'showBuildingInfoStep' | 'showHouseholdStep'>,
  currentStep: StepType,
) => {
  return getSteps(stepContext).indexOf(currentStep) + 1;
};

const isStepType = (stepNameOrType: StepName | StepType): stepNameOrType is StepType => {
  return stepNameOrType in StepType;
};

const ensureStepName = (stepNameOrType: StepName | StepType, layoutType?: LayoutType): StepName => {
  if (isStepType(stepNameOrType)) {
    switch (stepNameOrType) {
      case StepType.INTRO:
        // While technically this could also be STEP_NAMES.INTRO_MOBILE, in practice
        // that stepName is only active when the browser size is small enough, so it is not
        // something that can easily be determined in code.
        return STEP_NAMES.INTRO;
      case StepType.LOCATION:
        return STEP_NAMES.LOCATION;
      case StepType.BUILDING_INFO:
        return STEP_NAMES.HOME;
      case StepType.ROOM_INFO:
        if (layoutType === LayoutType.SINGLE_ROOM) {
          return STEP_NAMES.SINGLE_ROOM;
        }
        return STEP_NAMES.MULTI_ROOM;
      case StepType.ENERGY_INFO:
        return STEP_NAMES.ENERGY_INFO;
      case StepType.HOUSEHOLD:
        return STEP_NAMES.HOUSEHOLD;
      case StepType.RESULTS:
        return STEP_NAMES.RESULTS;
      default:
        break;
    }
  }

  return stepNameOrType;
};

const StepUtil = {
  urlFormat,
  getSteps,
  getCurrentStepNum,
  getPath,
  getPrevStepUrl,
  getNextStepUrl,
  getPathFromUrl,
  getPathsForStep,
  getStepURLsForAllLayoutTypes,
  ensureStepName,
  getAllPossibleStepPaths,
};

export default StepUtil;
